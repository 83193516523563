<template>
  <div class="pt-24 pr-16">
    <div class="pl-16">
      <!-- 搜索头 -->
      <search-header :schemaData="schema" @getDataList="handleFormSearch" />

      <!-- 列表 -->
      <div :style="{ height: tableHeight + 'px' }">
        <p-table
          height="auto"
          :dataSource="tableDataSource"
          id="service-configuration-table"
          @sort-change="sortChangeEvent"
        >
          <p-t-column type="seq" width="60" title="序号" fixed="left" />
          <p-t-column min-width="136" title="账号" field="card_no" />
          <p-t-column min-width="336" title="企业名称" field="company_name">
            <template v-slot="{ row }">
              <span>{{
                row.company_name?.length > 18
                  ? row.company_name?.substr(0, 18) + "..."
                  : row.company_name
              }}</span>
            </template>
          </p-t-column>
          <p-t-column min-width="186" title="流水号" field="trade_no" />
          <p-t-column
            min-width="86"
            title="账户类型"
            field="account_type_desc"
          />
          <p-t-column
            min-width="166"
            title="充值金额"
            field="recharge_amount"
            align="right"
          >
            <template v-slot="{ row }">
              {{
                row.recharge_amount && (row.recharge_amount / 100).toFixed(2)
              }}
            </template>
          </p-t-column>
          <p-t-column min-width="140" title="操作人" field="creator" />
          <p-t-column
            min-width="240"
            title="操作时间"
            field="create_time"
            sortable
          >
            <template v-slot="{ row }">
              {{
                row.create_time &&
                  formatToDateTime(row.create_time, "yyyy-MM-dd hh:mm:ss")
              }}
            </template>
          </p-t-column>
          <p-t-column min-width="140" title="备注" field="remark" />
          <p-t-column width="60" title="操作" fixed="right" field="img">
            <template v-slot="{ row }">
              <div>
                <a-button
                  type="link"
                  :disabled="!row.oss"
                  @click="() => preview(row.oss)"
                  >凭证</a-button
                >
              </div>
            </template>
          </p-t-column>

          <template #footer>
            <a-button class="mt-12 ml-32" @click="handleExport" type="primary"
              >导出</a-button
            >
          </template>
        </p-table>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, toRaw, onMounted } from "vue";
import { useRoute } from "vue-router";
import { Table } from "@wlhy-web-components/common";
import { searchHeaderOptions } from "./Constants";
import { preview } from "@/helper/preview-helper";
import useSearchForm from "@/hooks/UseSearchForm";
import {
  useRechargeRecordApi,
  useExportRechargeRecordApi
} from "@/apis/financial";
import { formatToDateTime } from "@wlhy-web-lib/shared";
import { exportExcel } from "@/hooks/useExport";
import { useEnumAccountTypeApi, useCompanyEnumApi } from "@/apis/enums";
import useSearch from "@/hooks/useSearch";
import $await from "@/utils/await";
import useDebounce from "@/hooks/useDebounce";
import useTableHeight from "@/hooks/useTableHeight";

export default {
  name: "service-order",
  setup() {
    // table高度
    const tableHeight = ref();

    const route = useRoute();
    const companyInfo = ref({});
    const exportRechargeRecordApi = useExportRechargeRecordApi();

    const rechargeRecordApi = useRechargeRecordApi();
    const enumAccountTypeApi = useEnumAccountTypeApi();
    const companyEnumApi = useCompanyEnumApi();

    let schemaList = searchHeaderOptions;
    let enumeration = searchHeaderOptions.find(v => v.field === "company_id");

    const handleSearch = useDebounce(async value => {
      const [, res] = await $await(
        companyEnumApi({ name: value, page: 1, size: 100 })
      );
      useSearch(searchHeaderOptions, res, "company_id", false);
    }, 500);
    handleSearch();

    (async () => {
      let [, account] = await $await(enumAccountTypeApi({ skip: "CONSUMER" }));
      schemaList = useSearch(searchHeaderOptions, account, "account_type");
      enumeration.props.onSearch = handleSearch;
    })();

    const { filter, handleFormSearch } = useSearchForm(schemaList);

    const tableDataSource = reactive({
      loading: false,
      data: [],
      error: "",
      meta: {
        total: 0,
        pageSize: 20,
        currentPageNo: 1
      },
      sum: {
        data: [],
        loading: false,
        error: ""
      }
    });

    const refresh = Table.useTableConfig({
      filter,
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          ...toRaw(filter.value),
          company_id: filter.value.company_id || route.query.id,
          start_time: filter.value.start_time || route.query.time,
          end_time: filter.value.end_time || route.query.time,
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        const res = await rechargeRecordApi(data);
        return { records: res.list, total: res.total };
      }
    });

    const handleExport = async () => {
      let res = exportRechargeRecordApi({
        ...toRaw(filter.value),
        company_id: filter.value.company_id || route.query.id,
        start_time: filter.value.start_time || route.query.time,
        end_time: filter.value.end_time || route.query.time
      });
      res.then(item => exportExcel(item, "充值记录"));
    };

    const sortChangeEvent = colums => {
      const { order } = colums;
      filter.value.sort = "create_time " + order;
      refresh();
    };

    onMounted(() => {
      tableHeight.value = useTableHeight();
    });

    return {
      tableHeight,
      tableDataSource,
      handleFormSearch,
      schema: searchHeaderOptions,
      companyInfo,
      preview,
      handleExport,
      formatToDateTime,
      sortChangeEvent
    };
  }
};
</script>

<style lang="less" scoped>
:deep(.search-header .operator-left) {
  margin-left: 0;
}
</style>
