/**
 * 搜索头
 */
export const searchHeaderOptions = [
  {
    field: "company_id",
    label: "企业名称",
    type: "select-data-search",
    defaultValue: null,
    iw: 160,
    props: {
      placeholder: "请输入企业名称"
    },
    options: []
  },
  {
    type: "select",
    field: "account_type",
    label: "账户类型",
    iw: 160,
    defaultValue: null,
    options: []
  },
  {
    field: ["start_time", "end_time"],
    iw: 324,
    label: "操作时间",
    type: "dateRange",
    props: {
      dateFormat: "YYYY/MM/DD"
    }
  }
];
