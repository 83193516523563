/**
 * 搜索头
 */
export const searchHeaderOptions = [
  {
    type: "select",
    field: "state",
    label: "状态",
    iw: 160,
    defaultValue: null,
    options: []
  }
];

export const state = {
  1: "处理中",
  2: "成功",
  3: "失败"
};
