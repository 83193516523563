<template>
  <div class="page-main-box recharge-withdraw-box">
    <Tabs v-model:activeKey="activeKey" :animated="false" :tabBarGutter="48">
      <TabPane key="RECHARGE" tab="充值">
        <Recharge v-if="activeKey === 'RECHARGE'" />
      </TabPane>
      <TabPane key="WITHDRAW" tab="提现">
        <Withdraw v-if="activeKey === 'WITHDRAW'" />
      </TabPane>
    </Tabs>
  </div>
</template>

<script>
import { ref } from "vue";
import { Tabs } from "ant-design-vue";
import Recharge from "./components/Recharge/index";
import Withdraw from "./components/Withdraw/index";
// import Reject from "./components/Reject/index";

export default {
  name: "account",
  components: { Tabs, TabPane: Tabs.TabPane, Recharge, Withdraw },
  setup() {
    return {
      activeKey: ref("RECHARGE")
    };
  }
};
</script>

<style lang="less" scoped></style>
<style lang="less">
.recharge-withdraw-box {
  .ant-tabs-bar {
    margin: 0;
  }
  .ant-tabs-nav-scroll {
    background: #fff;
    margin-bottom: 2px;
  }
  .ant-tabs-tab {
    color: #808080;
    padding: 12px 0;
    &:nth-child(1) {
      margin-left: 40px;
    }
  }
  .ant-tabs-tab-active {
    color: #007aff;
  }
}
</style>
