<template>
  <div>
    <xModal
      title="提现"
      :visible="visible"
      :w="488"
      :footer="null"
      @handleCancel="handleCancel"
    >
      <template #content>
        <Form
          ref="formRef"
          :model="formState"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          class="form-box"
        >
          <!-- 户名 -->
          <FormItem label="户名">
            <p class="input">{{ formState.account_name }}</p>
          </FormItem>

          <!-- 提现银行 -->
          <FormItem label="提现银行">
            <p class="input">{{ formState.bank_name }}</p>
          </FormItem>

          <!-- 提现卡号 -->
          <FormItem label="提现卡号">
            <p class="input">{{ formState.card_no }}</p>
          </FormItem>

          <!-- 余额 -->
          <FormItem label="余额">
            <p class="input">
              {{ formState.actual_usable_amt }}<span>元</span>
            </p>
          </FormItem>

          <!-- 提现金额-->
          <FormItem label="提现金额">
            <p class="input-number">
              <a-input-number
                v-model:value="amount"
                :max="formState.actual_usable_amt"
                style="width:310px;"
                placeholder="输入提现金额"
              />
              <span>元</span>
            </p>
          </FormItem>

          <FormItem class="pt-24" :wrapper-col="{ span: 17, offset: 7 }">
            <a-button @click="handleCancel">取消</a-button>
            <a-button
              class="ml-16"
              type="primary"
              @click="handleSubmit"
              :loading="isLoading"
              >确定</a-button
            >
          </FormItem>
        </Form>
      </template>
    </xModal>
  </div>
</template>

<script>
import { ref } from "vue";
import { Form, message } from "ant-design-vue";
import xModal from "@/components/xModal";
import $await from "@/utils/await";
import { useApplyWithdrawApi, useQueryAccountApi } from "@/apis/financial";
import { modalTitle } from "./Constants/index";

export default {
  props: {
    visible: { type: Boolean, default: false }
  },
  components: {
    xModal,
    Form,
    FormItem: Form.Item
  },
  setup(_, ctx) {
    const applyWithdrawApi = useApplyWithdrawApi();
    const queryAccountApi = useQueryAccountApi();

    const isLoading = ref(false);
    const formRef = ref();

    // 表单信息
    const formState = ref({});
    const amount = ref(0);

    // 获取账户信息
    (async () => {
      let [, res] = await $await(queryAccountApi());
      if (res) {
        formState.value = res;
      }
    })();

    // 提交表单
    const handleSubmit = () => {
      const data = {
        amount: amount.value
      };
      formRef.value
        .validate()
        .then(async () => {
          isLoading.value = true;
          const [, res] = await $await(applyWithdrawApi(data));
          if (res) {
            isLoading.value = false;
            message.success("提交成功!");
            handleCancel();

            if (res.apply_url) {
              window.open(res.apply_url);
            }
            // 更新列表
            ctx.emit("getData");
          } else {
            isLoading.value = false;
          }
        })
        .catch(error => {
          console.log("error", error);
        });
    };

    // 取消
    const handleCancel = () => {
      ctx.emit("cancel");
    };

    return {
      isLoading,
      formRef,
      amount,
      handleCancel,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      handleSubmit,
      formState,
      modalTitle
    };
  }
};
</script>

<style lang="less" scoped>
.input {
  background: #f5f5f5;
  border-radius: 4px;
  padding-left: 8px;
  height: 32px;
  line-height: 32px;
  position: relative;
  span {
    position: absolute;
    right: 8px;
    color: #4d4d4d;
  }
}
.input-number {
  border-radius: 4px;
  height: 32px;
  line-height: 32px;
  position: relative;
  span {
    position: absolute;
    right: 8px;
    color: #4d4d4d;
  }
}
</style>
<style lang="less"></style>
