<template>
  <div class="pt-24 pr-16">
    <div class="pl-16">
      <!-- 搜索头 -->
      <search-header :schemaData="schema" @getDataList="handleFormSearch" />

      <!-- 列表 -->
      <div :style="{ height: tableHeight + 'px' }">
        <p-table
          height="auto"
          :dataSource="tableDataSource"
          id="service-configuration-table"
          @sort-change="sortChangeEvent"
        >
          <p-t-column type="seq" width="60" title="序号" fixed="left" />
          <p-t-column min-width="220" title="流水号" field="user_withdraw_no" />
          <p-t-column
            min-width="166"
            title="金额（元）"
            field="amount"
            align="right"
          >
            <template v-slot="{ row }">
              {{ row.amount && row.amount.toFixed(2) }}
            </template>
          </p-t-column>
          <p-t-column min-width="166" title="提现银行" field="bind_bank_name" />

          <p-t-column
            min-width="206"
            title="提现卡号"
            field="bind_account_no"
          />
          <p-t-column min-width="86" title="状态" field="state">
            <template v-slot="{ row }">
              {{ state[row.state] }}
            </template>
          </p-t-column>

          <p-t-column min-width="140" title="操作人" field="creator" />
          <p-t-column min-width="240" title="时间" field="create_time" sortable>
            <template v-slot="{ row }">
              {{
                row.create_time &&
                  formatToDateTime(row.create_time, "yyyy-MM-dd hh:mm:ss")
              }}
            </template>
          </p-t-column>

          <template #footer>
            <a-button
              v-if="$store.getters.roles.includes('Withdraw')"
              class="mt-12 ml-32"
              @click="handleWithdraw"
              >提现</a-button
            >
            <a-button class="mt-12 ml-16" @click="handleExport" type="primary"
              >导出</a-button
            >
          </template>
        </p-table>
      </div>
    </div>

    <!-- 提现弹窗 -->
    <ToWithdraw
      :visible="visible"
      @getData="refresh"
      @cancel="visible = false"
    ></ToWithdraw>
  </div>
</template>

<script>
import { reactive, ref, toRaw, onMounted } from "vue";
import { useRoute } from "vue-router";
import { Table } from "@wlhy-web-components/common";
import ToWithdraw from "./components/ToWithdraw";
import { searchHeaderOptions, state } from "./Constants";
import { preview } from "@/helper/preview-helper";
import useSearchForm from "@/hooks/UseSearchForm";
import {
  useWithdrawPageApi,
  useExportWithdrawRecordApi
} from "@/apis/financial";
import { formatToDateTime } from "@wlhy-web-lib/shared";
import { exportExcel } from "@/hooks/useExport";
import { useWithdrawStatusEnumApi } from "@/apis/enums";
import useSearch from "@/hooks/useSearch";
import $await from "@/utils/await";
import useTableHeight from "@/hooks/useTableHeight";

export default {
  name: "service-order",
  components: { ToWithdraw },
  setup() {
    // table高度
    const tableHeight = ref();

    const route = useRoute();
    const companyInfo = ref({});

    const withdrawPageApi = useWithdrawPageApi();
    const exportWithdrawRecordApi = useExportWithdrawRecordApi();
    const withdrawStatusEnumApi = useWithdrawStatusEnumApi();

    const visible = ref(false);

    let schemaList = searchHeaderOptions;

    (async () => {
      let [, state] = await $await(withdrawStatusEnumApi());
      schemaList = useSearch(searchHeaderOptions, state, "state");
    })();

    const { filter, handleFormSearch } = useSearchForm(schemaList);

    const tableDataSource = reactive({
      loading: false,
      data: [],
      error: "",
      meta: {
        total: 0,
        pageSize: 20,
        currentPageNo: 1
      },
      sum: {
        data: [],
        loading: false,
        error: ""
      }
    });

    const refresh = Table.useTableConfig({
      filter,
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          ...toRaw(filter.value),
          company_id: filter.value.company_id || route.query.id,
          start_time: filter.value.start_time || route.query.time,
          end_time: filter.value.end_time || route.query.time,
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        const res = await withdrawPageApi(data);
        return { records: res.list, total: res.total };
      }
    });
    // 提现操作
    const handleWithdraw = () => {
      visible.value = true;
    };

    // 导出操作
    const handleExport = async () => {
      let res = exportWithdrawRecordApi({
        ...toRaw(filter.value),
        company_id: filter.value.company_id || route.query.id,
        start_time: filter.value.start_time || route.query.time,
        end_time: filter.value.end_time || route.query.time
      });
      res.then(item => exportExcel(item, "提现记录"));
    };

    // 排序
    const sortChangeEvent = colums => {
      const { order } = colums;
      filter.value.sort = "create_time " + order;
      refresh();
    };

    onMounted(() => {
      tableHeight.value = useTableHeight();
    });

    return {
      tableHeight,
      tableDataSource,
      handleFormSearch,
      schema: searchHeaderOptions,
      companyInfo,
      preview,
      handleExport,
      formatToDateTime,
      sortChangeEvent,
      state,
      handleWithdraw,
      visible,
      refresh
    };
  }
};
</script>

<style lang="less" scoped>
#company {
  position: relative;
  overflow: hidden;
}
:deep(.search-header .operator-left) {
  margin-left: 0;
}
</style>
